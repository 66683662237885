import React, { useEffect, useState } from 'react';
import { grid } from '@noths/polaris-client-ribbons-base';
import type { ProductCardProps } from '@noths/polaris-client-ribbons-design-system';
import { ProductCard } from '@noths/polaris-client-ribbons-design-system';
import {
  addParamsToHref,
  getDeliveryByEventLabel,
  getProductIdFromProductCode,
} from '@noths/polaris-client-utils';

import type { ProductListProps } from 'src/components/organisms/ProductList/types';
import { PRODUCT_CARD_GRID_ITEM_ID } from 'src/components/organisms/ProductPages/constants/elementIds';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import { PageType } from 'src/types/navigation';
import { PRODUCT_ID_DATA_ATTRIBUTE } from 'src/utils/scrollProductCardIntoView';
import * as styles from './ProductList.styles';

const INITIAL_NUMBER_OF_PRODUCTS_TO_DISABLE_LAZY_LOADING = 6;
export const PRODUCT_CARD_GRID_ITEM_TEST_ID = 'PRODUCT_CARD_GRID_ITEM_TEST_ID';

/**
 * Currently responsible for rendering:
 *  - lists of products
 *  - lists of expandedProducts (if expanded search scenario)
 *  - expanded search messaging
 *
 * Should be responsible for rendering:
 *  - a list of products (agnostic about whether it's a full page, exact results, expanded search results)
 * @component
 */
export const ProductList = ({
  expandedProducts,
  expandedResultsMessage,
  favouriteProductCodes = [],
  onProductClick,
  onProductVisible,
  page,
  pageType,
  partnerLinkToPPLP,
  products,
  providerAttributionToken,
  referredBy,
  updateFavourite,
  updatePageHistory,
}: ProductListProps) => {
  const [isClient, setIsClient] = useState(false);
  const [fadeInProductList] = useState(isClient);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleProductClick = (
    href: string,
    productCode: number,
    linkOpensInSameWindow: boolean,
  ) => {
    const hrefWithTrackingParams = addParamsToHref(href, {
      ...(providerAttributionToken && { attributionToken: providerAttributionToken }),
      ...(referredBy && { referredBy }),
    });

    if (linkOpensInSameWindow) {
      updatePageHistory({ page, productCode });
    }

    onProductClick({
      productUrl: hrefWithTrackingParams,
      destinationUrl: href,
      productCode,
      linkOpensInSameWindow,
    });
  };

  const handleProductVisible: ProductCardProps['onVisible'] = ({ code }) => {
    const productId = getProductIdFromProductCode(code);

    onProductVisible(productId);
  };

  const renderProducts = (products: BrowseDataAPIProduct[]) => {
    return products.map((product, index) => {
      const secondaryLabelText = product.deliveryByEvent?.arriveByEvent
        ? getDeliveryByEventLabel(product.deliveryByEvent.eventName)
        : undefined;
      const partnerHref =
        pageType !== PageType.Partner && partnerLinkToPPLP
          ? `/partners/${product.partner.shortcode}/products`
          : `/${product.partner.shortcode}`;

      return (
        <div
          css={styles.productCardGridItem}
          data-testid={PRODUCT_CARD_GRID_ITEM_TEST_ID}
          id={`${PRODUCT_CARD_GRID_ITEM_ID}-${index}`}
          key={`${product.code}`}
          {...{ [PRODUCT_ID_DATA_ATTRIBUTE]: getProductIdFromProductCode(product.code) }}
        >
          <ProductCard
            code={product.code}
            disableImageLazyLoading={index < INITIAL_NUMBER_OF_PRODUCTS_TO_DISABLE_LAZY_LOADING}
            freeDomesticDelivery={product.freeDomesticDelivery}
            href={product.links[0]?.rel || ''}
            imageSrc={product.images.length > 0 ? product.images[0].href : ''}
            isFavourited={favouriteProductCodes.includes(String(product.code))}
            isNew={product.isNew}
            onProductClick={handleProductClick}
            onSale={product.onSale}
            onVisible={handleProductVisible}
            partnerHref={partnerHref}
            partnerName={product.partner.name}
            preSalePrices={product.preSalePrices}
            price={product.price}
            productIndex={index}
            purchasable={product.purchasable}
            salePercentage={product.salePercentage}
            secondaryLabelText={secondaryLabelText}
            title={product.title}
            updateFavourite={updateFavourite}
          />
        </div>
      );
    });
  };

  return (
    <div css={[grid, styles.productListWrapper, fadeInProductList && styles.fadeIn]}>
      {products && renderProducts(products)}
      {expandedResultsMessage && expandedResultsMessage}
      {expandedProducts && renderProducts(expandedProducts)}
    </div>
  );
};
